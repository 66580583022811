import React from "react";
import ContactSection from "~sections/contact/ContactOne/ContactSection";
import FooterFive from '~sections/agency/FooterFive'
import { PageWrapper } from "~components/Core";
import BreadCrumbSection from "~sections/about/BreadCrumb";

const header = {
  headerClasses: "site-header site-header--menu-end dark-header site-header--sticky",
  containerFluid:false,
  darkLogo:false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnText="Appointments"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function contactOne() {
  return (
    <PageWrapper headerConfig={header} >
        <BreadCrumbSection title="Contact Us" text=""/>
        <ContactSection/>
        <FooterFive/>
    </PageWrapper>
  )
}
