import React from 'react'
import Contact from './style'
import Hero from "./style"
import { Link } from '~components';
import SectionTitle from './Components/SectionTitle'
import { Col, Container, Row } from 'react-bootstrap'
export default function ContactOne(){
return(
<Contact backgroundColor="#f3f4f6">
  <Container>
    <Row>
      <Col className="col-xl-7 col-lg-7">
       
        
    
     
        
        <Contact.Box >
            <SectionTitle
            subTitle="Offline Consultation" 
            title="In-Person Consultation"
            text="If you would like to meet Dr.M. Chandrasekaran in-person, you can schedule an appointment at Apollo Specialty Hospital,Teynampet. To book an appointment @ Apollo, call the number mentioned below."
            subTitleProps={{mb:"10px"}}
            titleProps={{mb:"10px",as:"h2"}}
            mb="50px" />
        </Contact.Box>

        
        <h4>Contact Number</h4>
        <h5><a href="tel:+918015161718"><u>+91-8015161718</u></a></h5>
        <br></br>
        <h4>Hospital Location</h4>
        <h5><a href="https://g.co/kgs/NsngM5"><u>View Apollo Hospitals Location</u></a></h5>
        <p><br></br></p>
         
        {/* <Contact.Box >
            <SectionTitle
            subTitle="" 
            title="Online Consultation"
            text="To book an Online Consultation session with Dr.M.Chandrasekaran click on the button below. "
            subTitleProps={{mb:"10px"}}
            titleProps={{mb:"10px",as:"h2"}}
            mb="50px" />
        </Contact.Box>
        <Contact.Button className="btn-primary btn-1" as={Link} to="https://app.cimplify.in/#/bookappointment/100002-Dr.MaharajanChandrasekaran">Book Online Appointment</Contact.Button>
        <br></br>
        <br></br> */}
      </Col>

      <Col xs="12" className="col-xl-5 col-lg-5">
        <Contact.WidgetsBox className="contact-widget-box">
            <Contact.WidgetsBoxTitle as="h2">Get In Touch</Contact.WidgetsBoxTitle>
            <Contact.WidgetsBoxText as="p">You can reach the McArthy Thyroid Clinic Team via phone or email.</Contact.WidgetsBoxText>
          <Row>
            
            <Col xs="12" className="col-lg-12 col-sm-6">
            <Contact.Widgets>
              <Contact.WidgetsIcon>
                <i className="fas fa-phone-alt" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsText as="h3">McArthy Phone:</Contact.WidgetsText>
                <Contact.WidgetsTitle as="h3" a href="tel:+918015161718">+918015161718</Contact.WidgetsTitle>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
            <Col xs="12" className="col-lg-12 col-sm-6 active">
            <Contact.Widgets>
              <Contact.WidgetsIcon className="active">
                <i className="fas fa-envelope" />
              </Contact.WidgetsIcon>
              <Contact.WidgetsBoxBody>
                <Contact.WidgetsText as="h3">McArthy Mail:</Contact.WidgetsText>
                <Contact.WidgetsTitle as="p">mc@thyroidclinic.com</Contact.WidgetsTitle>
              </Contact.WidgetsBoxBody>
              </Contact.Widgets>
            </Col>
            
          </Row>
        </Contact.WidgetsBox>
      </Col>
    </Row>
  </Container>
</Contact>

)
}